var render = function () {
  var _vm$analytics$electri, _vm$analytics$electri2, _vm$analytics$electri3, _vm$analytics$gasCon, _vm$analytics$gasCon2, _vm$analytics$gasCon3, _vm$analytics$waterC, _vm$analytics$waterC2, _vm$analytics$waterC3, _vm$analytics$solarC, _vm$analytics$solarC2, _vm$analytics$solarC3, _vm$analytics$wasteC, _vm$analytics$wasteC2, _vm$analytics$wasteC3, _vm$analytics$wasteC4, _vm$analytics$consump, _vm$analytics$consump2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.selectedAssetGroup._id || _vm.selectedAsset._id ? _c('div', [_c('div', {
    staticClass: "row mb-4"
  }, [_vm.availableAccountTypes.includes('electricity') ? _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$electri = _vm.analytics['electricity-consumption']) === null || _vm$analytics$electri === void 0 ? void 0 : _vm$analytics$electri.totalConsumption,
      "prev-value": (_vm$analytics$electri2 = _vm.analytics['electricity-consumption-prev']) === null || _vm$analytics$electri2 === void 0 ? void 0 : _vm$analytics$electri2.totalConsumption,
      "precision": "0",
      "icon-class": "fa-plug text-primary",
      "description": "Electricity",
      "units": "kWh",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['electricity-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['electricity-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['electricity-consumption'],
      "estimated-percentage": _vm.generateTotalEstimateConsumption('electricity') / ((_vm$analytics$electri3 = _vm.analytics['electricity-consumption']) === null || _vm$analytics$electri3 === void 0 ? void 0 : _vm$analytics$electri3.totalConsumption)
    }
  })], 1) : _vm._e(), _vm.availableAccountTypes.includes('gas') ? _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$gasCon = _vm.analytics['gas-consumption']) === null || _vm$analytics$gasCon === void 0 ? void 0 : _vm$analytics$gasCon.totalConsumption,
      "prev-value": (_vm$analytics$gasCon2 = _vm.analytics['gas-consumption-prev']) === null || _vm$analytics$gasCon2 === void 0 ? void 0 : _vm$analytics$gasCon2.totalConsumption,
      "precision": "0",
      "icon-class": "fa-flame text-primary",
      "description": "Gas",
      "units": "kWh",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['gas-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['gas-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['gas-consumption'],
      "estimated-percentage": _vm.generateTotalEstimateConsumption('gas') / ((_vm$analytics$gasCon3 = _vm.analytics['gas-consumption']) === null || _vm$analytics$gasCon3 === void 0 ? void 0 : _vm$analytics$gasCon3.totalConsumption)
    }
  })], 1) : _vm._e(), _vm.availableAccountTypes.includes('water') ? _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$waterC = _vm.analytics['water-consumption']) === null || _vm$analytics$waterC === void 0 ? void 0 : _vm$analytics$waterC.totalConsumption,
      "prev-value": (_vm$analytics$waterC2 = _vm.analytics['water-consumption-prev']) === null || _vm$analytics$waterC2 === void 0 ? void 0 : _vm$analytics$waterC2.totalConsumption,
      "precision": "0",
      "icon-class": "fa-water text-primary",
      "description": "Water",
      "units": "m3",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['water-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['water-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['water-consumption'],
      "estimated-percentage": _vm.generateTotalEstimateConsumption('water') / ((_vm$analytics$waterC3 = _vm.analytics['water-consumption']) === null || _vm$analytics$waterC3 === void 0 ? void 0 : _vm$analytics$waterC3.totalConsumption)
    }
  })], 1) : _vm._e(), _vm.availableAccountTypes.includes('solar') ? _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$solarC = _vm.analytics['solar-consumption']) === null || _vm$analytics$solarC === void 0 ? void 0 : _vm$analytics$solarC.totalConsumption,
      "prev-value": (_vm$analytics$solarC2 = _vm.analytics['solar-consumption-prev']) === null || _vm$analytics$solarC2 === void 0 ? void 0 : _vm$analytics$solarC2.totalConsumption,
      "precision": "0",
      "icon-class": "fa-solar-panel text-primary",
      "description": "Solar (Gen)",
      "units": "kWh",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['solar-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['solar-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['solar-consumption'],
      "estimated-percentage": _vm.generateTotalEstimateConsumption('solar') / ((_vm$analytics$solarC3 = _vm.analytics['solar-consumption']) === null || _vm$analytics$solarC3 === void 0 ? void 0 : _vm$analytics$solarC3.totalConsumption)
    }
  })], 1) : _vm._e(), _vm.availableAccountTypes.includes('waste') ? _c('div', {
    staticClass: "col"
  }, [_c('Block', [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$wasteC = _vm.analytics['waste-consumption']) === null || _vm$analytics$wasteC === void 0 ? void 0 : _vm$analytics$wasteC.totalConsumption,
      "prev-value": (_vm$analytics$wasteC2 = _vm.analytics['waste-consumption-prev']) === null || _vm$analytics$wasteC2 === void 0 ? void 0 : _vm$analytics$wasteC2.totalConsumption,
      "precision": "0",
      "icon-class": "fa-waste",
      "description": "Waste",
      "units": "kg",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['waste-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['waste-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['waste-consumption'],
      "estimated-percentage": ((_vm$analytics$wasteC3 = _vm.analytics['waste-consumption']) === null || _vm$analytics$wasteC3 === void 0 ? void 0 : _vm$analytics$wasteC3.estimatedConsumption) * 100 / ((_vm$analytics$wasteC4 = _vm.analytics['waste-consumption']) === null || _vm$analytics$wasteC4 === void 0 ? void 0 : _vm$analytics$wasteC4.totalConsumption)
    }
  })], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "bg-lighter rounded-md px-4 py-3 mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Granularity")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "granularity",
      "type": "select",
      "options": _vm.granularityOptions,
      "disabled": _vm.loadingAction.getConsumptions
    },
    on: {
      "input": _vm.onGranularityUpdate
    },
    model: {
      value: _vm.analyticsOptions.granularity,
      callback: function callback($$v) {
        _vm.$set(_vm.analyticsOptions, "granularity", $$v);
      },
      expression: "analyticsOptions.granularity"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Utility Type")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "accountType",
      "type": "select",
      "options": _vm.typeOptions
    },
    on: {
      "input": _vm.onTypeUpdate
    },
    model: {
      value: _vm.analyticsOptions.accountType,
      callback: function callback($$v) {
        _vm.$set(_vm.analyticsOptions, "accountType", $$v);
      },
      expression: "analyticsOptions.accountType"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Highlight")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "highlight",
      "type": "select",
      "options": [{
        label: 'None',
        value: ''
      }, {
        label: 'Source',
        value: 'source'
      }, {
        label: 'Estimated',
        value: 'estimated'
      }, {
        label: 'Compare To',
        value: 'compare'
      }],
      "disabled": _vm.loadingAction.getConsumptions
    },
    on: {
      "input": _vm.refreshChart
    },
    model: {
      value: _vm.filters.highlight,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "highlight", $$v);
      },
      expression: "filters.highlight"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Graph Type")]), _c('a', {
    staticClass: "font-w600 px-3 py-1",
    class: {
      'bg-primary text-white border rounded-md': _vm.filters.chartType === 'bar'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.filters.chartType = 'bar';
      }
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-chart-column mr-1"
  }), _vm._v(" Bar")]), _c('a', {
    staticClass: "font-w600 px-3 py-1",
    class: {
      'bg-primary text-white border rounded-md': _vm.filters.chartType === 'line'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.filters.chartType = 'line';
      }
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-chart-line mr-1"
  }), _vm._v(" Line")])])])]), !_vm.loading && !_vm.loadingAction.getAnalytics['consumption'] && (_vm$analytics$consump = _vm.analytics['consumption']) !== null && _vm$analytics$consump !== void 0 && (_vm$analytics$consump2 = _vm$analytics$consump.data) !== null && _vm$analytics$consump2 !== void 0 && _vm$analytics$consump2.length ? _c('Chart', {
    staticClass: "mb-4",
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "option": _vm.consumptionChart
    }
  }) : _c('SpinnerLogo')], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }